[data-component="UserMenu"] {

  position: absolute;
  width: 100%;
  height: 100%;
  padding: var(--unit-lg);
  padding-left: var(--unit-md);
  display: flex;
  align-items: center;

  a, a:link, a:visited, a:active {
    text-decoration: none !important;
    color: inherit !important;
  }

  &:hover {
    .user-info {
      background: var(--background-tertiary);
    }
  }

  .user-info {
    display: flex;
    height: var(--form-md);
    align-items: center;
    border-radius: var(--radius-lg);
    width: 100%;
    padding: var(--unit-md);
    padding-left: calc(var(--unit-lg));
    padding-right: 0px;
    &.active {
      background: var(--blue-main);
      .user-avatar {
        box-shadow: 0 0 2px var(--color-primary);
      }
      .user-nameplate .user-name-display .user-name svg {
        color: var(--color-primary);
        opacity: 0.6;
      }
      .user-nameplate .user-name-display .user-plan {
        color: var(--color-primary);
        opacity: 0.8;
      }
    }
    .user-nameplate {
      display: flex;
      align-items: center;
      gap: var(--unit-lg);
      overflow: hidden;
      .user-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--form-sm);
        height: var(--form-sm);
        background: var(--green-main);
        font-weight: var(--font-weight-bold);
        color: var(--color-primary);
        border-radius: 1000px;
      }
      .user-name-display {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .user-name {
          display: flex;
          align-items: center;
          overflow: hidden;
          gap: var(--unit-sm);
          img.icon {
            width: var(--unit-lg);
            height: var(--unit-lg);
          }
          & > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          svg {
            flex-shrink: 0;
            width: var(--unit-lg);
            height: var(--unit-lg);
            color: var(--color-tertiary);
          }
        }
        .user-plan {
          color: var(--color-tertiary);
          font-size: var(--font-size-sm);
        }
      }
    }
  }

  .user-menu-guest {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: var(--unit-lg);
    color: var(--color-disabled);
    a {
      padding: var(--unit-md);
      border-radius: var(--radius-lg);
      color: var(--color-primary) !important;
      &:hover {
        background: var(--background-tertiary);
      }
    }
  }

}