[data-component="AgentsCode"] {

  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  container-type: inline-size;
  border-top: 1px solid var(--background-secondary);
  background: var(--background-primary);

  &:has(.no-package) {
    align-items: center;
    justify-content: center;
  }

  .no-package {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: var(--unit-md);
    position: relative;
    background: var(--background-secondary);
    max-width: calc(100% - var(--unit-xl) * 2);
    padding: var(--unit-xl);
    border-radius: var(--radius-lg);
    gap: var(--unit-lg);
  }

  .package-error,
  .package-log,
  .package-save-error {
    position: absolute;
    top: -50%;
    // Alignment due to margin
    margin: 0px var(--unit-md);
    background-color: var(--red-dark);
    color: var(--white);
    border-radius: var(--radius-lg);
    padding: var(--unit-lg);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fade-in 0.1s ease-in-out;
    .package-log-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: calc(var(--unit-lg));
        height: calc(var(--unit-lg));
        color: var(--color-disabled);
        animation: spin 1.5s ease-in-out infinite;
      }
    }
    & > span {
      flex-shrink: 0;
      padding: 0px var(--unit-md);
    }
    .spacer {
      flex-grow: 1;
    }
  }

  .package-log {
    background-color: var(--background-secondary);
    color: var(--color-primary);
  }

  .package-save-error {
    top: auto;
    left: auto;
    bottom: var(--unit-lg);
    right: var(--unit-md);
    transform: none;
    margin: var(--unit-md);
    margin-bottom: calc(var(--form-lg) - var(--unit-md));
    white-space: pre-wrap;
    z-index: 2;
  }

  .agent-code-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 0;
    background: var(--background-primary);

    .agent-code-editor {
      flex-grow: 1;
      position: relative;
      overflow: hidden;
    }
  
    .agent-code-chat {
      flex-shrink: 0;
      width: 300px;
      position: relative;
      background: linear-gradient(to bottom, var(--background-primary), var(--background-secondary));
      border-left: 1px solid var(--background-secondary);
      transition: width 0.1s ease-in-out;
      overflow: hidden;
      .agent-code-chat-actions {
        position: absolute;
        top: var(--unit-md);
        right: var(--unit-md);
        z-index: 1;
      }
      .agent-code-chat-box {
        position: relative;
        overflow: hidden;
        height: 100%;
        width: 300px;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  @container (max-width: 1000px) {
    .agent-code-container {
      flex-direction: column;
      // margin-bottom: 0px;
      .agent-code-chat {
        width: 100%;
        height: 200px;
        padding-bottom: 0px;
        margin-top: 0px;
        border-left: none;
        border-top: 1px solid var(--background-secondary);
        transition: height 0.1s ease-in-out;
        .agent-code-chat-box {
          height: 200px;
          width: 100%;
          top: 0;
          // border-bottom-left-radius: 0px;
          // border-bottom-right-radius: 0px;
        }
      }
    }
    .fixed {
      [data-component="Textbox"] {
        textarea, input {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          border-top-color: var(--outline-hover);
        }
      }
    }
  }

  .fixed {
    display: none;
    // display: flex;
    flex-shrink: 0;
    padding: var(--unit-md);
    padding-top: 0px;
    gap: var(--unit-sm);
    align-items: flex-end;
  }

  &[data-is-debugging="false"] {
    .agent-code-chat {
      width: 0px;
      border-left: none;
    }
    @container (max-width: 1000px) {
      .agent-code-chat {
        width: auto;
        height: 0px;
        border-top: none;
      }
    }
  }

}