[data-component="BillingSubscriptionPage"] {

  position: relative;
  max-width: var(--screen-md);
  width: 100%;
  padding: var(--unit-xl) calc(var(--unit-xl) * 2);

  @media screen and (max-width:567px) {
    padding: var(--unit-xl) var(--unit-lg);  
  }

  .active-plan {
    display: flex;
    margin-bottom: var(--unit-lg);
  }

  .pricing-plans {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--unit-lg);

    @media screen and (max-width:567px) {
      gap: var(--unit-xl);  
    }
  }

  hr {
    border-color: var(--outline-default);
  }

  .subscription-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--unit-lg);
    padding: var(--unit-lg);
  }

}