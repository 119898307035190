
[data-component="ConstructionWarning"] {
  display: flex;
  align-items: center;
  margin-bottom: var(--unit-lg);
  &[data-center="true"] {
    justify-content: center;
  }
  .construction-warning-content {
    display: flex;
    gap: var(--unit-md);
    align-items: center;
    background-color: var(--background-secondary);
    color: var(--color-tertiary);
    border: 1px solid var(--outline-minimal);
    border-radius: var(--radius-lg);
    padding: var(--unit-md) var(--unit-lg);
    max-width: 500px;
    img {
      image-rendering: pixelated;
      height: 32px;
    }
  }
}

