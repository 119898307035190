[data-component="Toast"] {

  display: flex;
  align-items: flex-start;
  padding: var(--unit-md) var(--unit-lg);
  gap: var(--unit-lg);
  font-size: var(--font-size-md);
  border-radius: var(--radius-lg);
  color: var(--white);
  box-shadow: var(--shadow-dark-small);
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.1s ease-out, transform 0.1s ease-out;
  margin-bottom: var(--unit-md);
  
  &.visible {
    opacity: 1;
    transform: translateX(0px);
  }
  
  &.hidden {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.3s, transform 0.3s;
  }
  
  &.toast-success {
    background-color: var(--green-dark);
  }
  
  &.toast-error {
    background-color: var(--red-dark);
  }
  
  &.toast-info {
    background-color: var(--blue-dark);
  }
  
  &.toast-warning {
    background-color: var(--orange-dark);
  }

  .toast-icon {
    margin-top: var(--unit);
    width: calc(var(--unit-lg));
    height: calc(var(--unit-lg));
  }

  .toast-message {
    display: flex;
    align-items: center;
    white-space: pre-wrap;
  }

} 