[data-component="ActionButton"] {
  cursor: pointer;
  position: relative;
  z-index: 1;
  .icon-container {
    position: relative;
    display: flex;
    gap: var(--unit-md);
    align-items: center;
    padding: calc(var(--unit-md) + var(--unit-sm) - 1px);
    border-radius: var(--radius-lg);
    border: 1px solid transparent;
    z-index: 2;
    user-select: none;
    .icon {
      width: calc(var(--unit-lg) * 1.5);
      height: calc(var(--unit-lg) * 1.5);
      stroke-width: 1;
      color: var(--color-disabled);
    }
    img {
      width: calc(var(--unit-lg) * 1.5);
      height: calc(var(--unit-lg) * 1.5);
      border-radius: 1000px;
    }
  }
  &:not([data-flush="true"]) {
    .icon-container {
      background: var(--background-secondary);
      .icon {
        color: var(--color-primary);
      }
    }
    &:hover {
      .icon-container {
        background: var(--background-primary);
      }
    }
  }
  &[data-use-opacity="true"] {
    .icon-container .icon {
      color: var(--color-primary);
      opacity: 0.6;
    }
  }
  &[data-size="large"] {
    .icon-container {
      padding: calc(var(--unit-md) + var(--unit-sm) - 1px);
      gap: calc(var(--unit-md) + var(--unit-sm));
    }
  }
  &[data-button="true"] {
    .icon-container {
      background: var(--background-primary);
      border: 1px solid var(--outline-default);
      .icon {
        color: var(--color-disabled);
      }
      &:hover {
        background: var(--background-primary);
        border-color: var(--outline-hover);
        .icon {
          color: var(--color-primary);
        }
      }
      &:active {
        border-color: var(--outline-active);
        .icon {
          color: var(--color-primary);
        }
      }
    }
  }
}