[data-component="PackageDetailsPage"] {

  margin-bottom: calc(var(--unit-xl) * 2);

  .packages-list {
    max-width: var(--screen-sm);
    margin: 0px auto;
    & > [data-component] {
      margin: var(--unit-md) 0px;
    }
  }

  .package-details {
    background: var(--background-secondary);
    border-radius: var(--radius-lg);
    padding: var(--unit-md);
  }

  .package-version {
    position: relative;
    margin-bottom: var(--unit-md);
    display: flex;
    align-items: center;
    .env-name {
      text-align: center;
      width: 33%;
      padding: var(--unit-md);
      border-radius: var(--radius-lg);
      &:nth-child(2) {
        width: 34%;
      }
      &.selected {
        font-weight: var(--font-weight-medium);
        background: var(--background-tertiary);
      }
      span.inactive {
        color: var(--color-disabled);
      }
    }
  }

  .package-files {
    table {
      width: 100%;
      border: 1px solid var(--outline-default);
      border-radius: var(--radius-lg);
      padding: var(--unit-md);
    }
    td.icon {
      width: 32px;
      text-align: center;
      svg {
        fill: currentColor;
        width: calc(var(--unit-md) + var(--unit-sm));
        height: calc(var(--unit-md) + var(--unit-sm));
      }
    }
    td.size {
      text-align: right;
      color: var(--color-disabled);
    }
  }

  .package-filename {
    background: none;
  }

  .package-file-details {
    position: relative;
  }

}