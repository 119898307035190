[data-component="DataTable"] {
  display: flex;
  flex-direction: column;
  background: var(--background-secondary);
  border: 1px solid var(--outline-default);
  border-radius: var(--radius-lg);
  overflow: hidden;
  
  .table-container {
    display: flex;
    flex-direction: column;
    background: var(--background-primary);
    
    .table-loading-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--darken);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      
      p {
        color: var(--color-primary);
        font-size: var(--font-size-lg);
      }
    }

    .table-actions {
      padding: var(--unit-md);
      padding-bottom: 0;
      display: flex;
      justify-content: space-between;
      background: var(--background-tertiary);
      z-index: 10;
      flex-wrap: wrap-reverse;
      gap: var(--unit-sm);


      .actions-left {
        display: flex;
        flex-grow: 1;
        gap: var(--unit-sm);
      }

      .actions-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap-reverse;
        gap: var(--unit-sm);
      }

      .delete-button, .insert-row-button {
        display: flex;
        flex-shrink: 0;
        height: var(--form-sm);
        align-items: center;
        gap: var(--unit-md);
        padding: var(--unit-sm) var(--unit-md);
        border-radius: var(--radius-lg);
        border: 1px solid var(--outline-default);
        background: var(--background-primary);
        color: var(--color-primary);
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        font-family: inherit;
        font-size: var(--font-size-sm);
        transform: translateY(0px);

        svg {
          color: var(--color-secondary);
        }

        &:hover {
          border-color: var(--outline-hover);
          transform: translateY(-2px);
        }

        &:active {
          border-color: var(--outline-active);
          transform: translateY(1px);
        }

        &.disabled {
          opacity: 0.3;
          cursor: not-allowed;
          border-color: var(--outline-minimal);
          color: var(--color-disabled);
          background: var(--background-secondary);

          svg {
            color: var(--color-disabled);
          }

          &:hover {
            background: var(--background-secondary);
            border-color: var(--outline-minimal);
          }
        }
      }

      .query-container {
        display: flex;
        cursor: default;
        height: var(--form-sm);
        align-items: center;
        gap: var(--unit-md);
        max-width: 300px;
        border: 1px solid var(--outline-default);
        border-radius: var(--radius-lg);
        padding: 0px var(--unit-md);
        background: var(--background-primary);
        font-family: var(--font-family-monospace);
        .query-icon {
          display: flex;
          align-items: center;
          color: var(--color-disabled);
        }
        .query-text {
          font-size: var(--font-size-sm);
          color: var(--color-secondary);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        a, a:link, a:visited, a:active {
          display: flex;
          align-items: center;
          color: var(--color-disabled);
          cursor: pointer;
          &:hover {
            color: var(--color-primary);
          }
        }
      }

      .table-search {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: var(--unit-sm);

        select {
          height: 32px;
          border: 1px solid var(--outline-default);
          border-radius: var(--radius-lg);
          background: var(--background-primary);
          color: var(--color-primary);
          padding: 0 var(--unit-md);
          font-family: inherit;
          font-size: var(--font-size-sm);
          outline: none;
          cursor: pointer;
          appearance: none;
          padding-right: calc(var(--unit-md) * 3);

          &:focus {
            border-color: var(--outline-active);
          }
        }

        .search-input {
          position: relative;
          display: flex;
          align-items: center;

          svg {
            position: absolute;
            left: var(--unit-md);
            color: var(--color-disabled);
            transition: color 0.2s ease;
          }

          &:focus-within {
            svg {
              color: var(--outline-active);
            }
          }

          .search-loading {
            position: absolute;
            right: calc(var(--unit-lg) * 2);
            pointer-events: none;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0px;
            bottom: 0px;
            svg {
              color: var(--color-disabled);
              animation: loading-spin 1s ease-in-out infinite;
            }
          }

          input {
            height: 32px;
            width: 200px;
            padding: 0 calc(var(--unit-md) * 2 + 14px);
            border: 1px solid var(--outline-default);
            border-radius: var(--radius-lg);
            background: var(--background-primary);
            color: var(--color-primary);
            font-family: inherit;
            font-size: var(--font-size-sm);
            outline: none;

            &:focus {
              border-color: var(--outline-active);
            }
          }
        }

        .select-container {
          position: relative;

          &:focus-within {
            &:after {
              border-right-color: var(--outline-active);
              border-bottom-color: var(--outline-active);
            }
          }

          &:after {
            content: '';
            position: absolute;
            right: calc(var(--unit-md) + var(--unit-sm));
            top: 50%;
            transform: translateY(-50%);
            width: 0px;
            height: 0px;
            border: 3px solid transparent;
            border-right-color: var(--color-disabled);
            border-bottom-color: var(--color-disabled);
            transform: translateY(-70%) rotate(45deg);
            pointer-events: none;
          }
        }
      }
    }

    .table-scroll {
      position: relative;
      overflow-y: auto;
      height: 400px;

      table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        border-spacing: 0;

        thead {
          position: sticky;
          top: 0;
          z-index: 9;
          background: var(--background-tertiary);

          th {
            padding: var(--unit-md);
            text-align: left;
            font-weight: var(--font-weight-bold);
            font-size: var(--font-size-sm);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-right: 1px solid var(--background-tertiary);

            &:last-child {
              border-right: none;
            }

            &:has(.checkbox) {
              text-align: center;
              vertical-align: top;
              width: 40px;
              padding: 0 var(--unit-md);
              padding-top: var(--unit-md);
            }

            &:has([data-index="true"]) {
              width: 64px;
            }

            &:not(:has([data-index="true"])):not(:has(.checkbox)) {
              flex: 1;
              min-width: 0;
            }

            .column-header {
              display: flex;
              align-items: center;
              gap: var(--unit-sm);
              min-width: 0;

              .column-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .column-type {
                flex-shrink: 0;
                font-size: var(--font-size-xs);
                font-weight: var(--font-weight-medium);
                letter-spacing: 1px;
                padding: 2px var(--unit-sm);
                background: var(--background-primary);
                border: 1px solid var(--outline-minimal);
                border-radius: var(--radius-sm);
                color: var(--color-secondary);
              }
            }
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid var(--outline-minimal);

            &:last-child {
              border-bottom: none;
            }

            &:hover:not(:has(.empty-state)) {
              background: var(--background-secondary);
            }
          }

          td {
            padding: 0;
            vertical-align: top;
            font-size: var(--font-size-sm);
            border-right: 1px solid var(--outline-minimal);
            position: relative;

            &:last-child {
              border-right: none;
            }

            &:has(.checkbox) {
              text-align: center;
              vertical-align: top;
              width: 40px;
              padding: 0 var(--unit-md);
              padding-top: var(--unit-md);
            }

            &:has([data-index="true"]) {
              width: 64px;
            }

            &:not(:has(.checkbox)):not(:has([data-index="true"])) {
              flex: 1;
              min-width: 0;
            }
          }
        }
      }

      .empty-state {
        position: relative;
        display: flex;
        width: 100%;
        align-items: flex-start;
        justify-content: center;
        padding: var(--unit-xl)var(--unit-md);
        gap: var(--unit-md);
        
        p {
          color: var(--color-disabled);
          margin: 0;
        }

        &.loading {
          height: 100%;
          align-items: center;
          svg {
            color: var(--color-disabled);
            animation: loading-spin 1s ease-in-out infinite;
          }
        }
      }
    }
  }

  .table-pagination {
    display: grid;
    width: 220px;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;

    .nav-left {
      display: flex;
      gap: var(--unit-md);
      justify-self: start;
    }

    .nav-right {
      display: flex;
      gap: var(--unit-md);
      justify-self: end;
    }

    .pagination-info {
      color: var(--color-secondary);
      font-size: var(--font-size-sm);
      display: flex;
      align-items: center;
      gap: var(--unit-md);

      .separator {
        color: var(--color-disabled);
      }
    }

    .page-nav {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--unit-sm);
      border-radius: var(--radius);
      border: 1px solid var(--outline-default);
      background: var(--background-primary);
      color: var(--color-primary);
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover:not(:disabled) {
        background: var(--background-tertiary);
        border-color: var(--outline-hover);
      }

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
        border-color: var(--outline-minimal);
        color: var(--color-disabled);
        background: var(--background-secondary);
      }
    }
  }

  .checkbox {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background: var(--blue-main);
        border-color: var(--blue-main);
        
        svg {
          opacity: 1;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background: var(--background-primary);
      border: 1px solid var(--outline-default);
      border-radius: var(--radius-sm);
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;

      svg {
        color: white;
        opacity: 0;
        transition: opacity 0.2s ease;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
