[data-component="MiniEditor"] {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: var(--font-family);
  font-size: var(--font-size-sm);
  color: var(--color-tertiary);
  .mini-editor-header {
    display: flex;
    align-items: center;
    padding: var(--unit-sm);
    background: var(--background-tertiary);
    border-radius: var(--radius-lg) var(--radius-lg) 0 0;
    .mini-editor-icon {
      width: calc(var(--unit-md) + var(--unit-sm));
      height: calc(var(--unit-md) + var(--unit-sm));
      margin: 0px var(--unit-sm);
    }
    .mini-editor-label {
      padding: 0px var(--unit-sm);
    }
    & + .mini-editor-content control[control="CPHEditor"] {
      border-radius: 0 0 var(--radius-lg) var(--radius-lg);
      border-color: var(--background-tertiary) !important;
    }
  }
  .mini-editor-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &[data-type="error"] {
    // .mini-editor-header {
    //   color: var(--white);
    //   background: var(--red-dark);
    // }
    // .mini-editor-content control[control="CPHEditor"] {
    //   border-color: var(--red-dark);
    // }
    .mini-editor-header {
      color: var(--red-main);
    }
  }
}