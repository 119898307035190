[data-component="NewAgentFlow"] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding: var(--unit-lg);
  background: linear-gradient(to bottom, var(--background-primary), var(--background-secondary));
  padding-bottom: calc(var(--unit-lg) * 5);
  overflow: hidden;
  .content {
    width: 100%;
    max-width: 640px;
    background-color: var(--background-secondary);
    border-radius: var(--radius-lg);
    padding: var(--unit-lg);
    box-shadow: var(--shadow-dark-small, 0 4px 12px rgba(0, 0, 0, 0.15));
    margin-bottom: var(--unit-lg);
    
    h2 {
      margin: 0;
      margin-bottom: var(--unit-md);
      color: var(--color-primary);
    }
    
    p {
      margin-bottom: var(--unit-md);
      color: var(--color-secondary);
    }
  }
  
  .form-row {
    margin-bottom: var(--unit-md);
  }
  
  .action-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: var(--unit-lg);
    flex-wrap: wrap-reverse;
    gap: var(--unit-md);
    
    .spacer {
      flex-grow: 1;
    }
    
    .discord-action {
      margin-right: var(--unit-md);
    }
    
    .discord-linked {
      display: flex;
      align-items: center;
      gap: var(--unit-sm);
      color: var(--brand-green);
    }
  }

  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: var(--radius-lg);
    padding: var(--unit-md);
    [data-component="DiscordLinkCard"] {
      width: 300px;
    }
  }
  
  // Discord connections styling
  .connections-container {
    background-color: var(--background-tertiary);
    border-radius: var(--radius-lg);
    padding: var(--unit-md);
    margin-top: var(--unit-md);
    margin-bottom: var(--unit-md);
    
    .discord-connected {
      display: flex;
      align-items: center;
      
      .discord-icon {
        width: 42px;
        height: 42px;
        background-color: #5865F2;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: var(--unit-md);
        
        svg {
          width: 24px;
          height: 24px;
          fill: white;
        }
      }
      
      .connection-details {
        flex-grow: 1;
        
        .connection-name {
          font-weight: 500;
          margin-bottom: 4px;
        }
        
        .server-name {
          color: var(--color-tertiary);
          font-size: 0.9em;
        }
      }
    }
    
    .discord-connect {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: var(--unit-md);
      gap: var(--unit-md);
      
      .connect-info {
        margin-top: var(--unit-sm);
        text-align: center;
        font-size: 0.9em;
        color: var(--color-tertiary);
      }
    }
  }
  
  // Step tracker styling with position-based classes
  .step-tracker-container {
    position: absolute;
    display: block;
    bottom: 0px;
    width: 100%;
    max-width: 300px;
    overflow: visible;
    margin-top: var(--unit-lg);
    .step-tracker {
      position: relative;
      width: 100%;
      display: flex;
      padding-bottom: var(--unit-lg);
      padding-top: var(--unit-md);
      z-index: 10;
      
      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        position: relative;
        flex: 1;
        z-index: 1;
        
        .step-icon {
          width: 42px;
          height: 42px;
          border-radius: 1000px;
          background-color: transparent;
          border: 3px solid transparent;
          color: var(--color-disabled);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 8px;
          transition: all 0.2s ease;
          
          svg {
            width: 20px;
            height: 20px;
            stroke-width: 2.5;
          }
        }
        
        .step-label {
          font-size: 0.9em;
          font-weight: 500;
          transition: color 0.2s ease;
          text-align: center;
          color: var(--color-disabled);
        }
        
        &:hover .step-label,
        &.active .step-label {
          color: var(--color-primary);
        }
        
        // 1. Active step
        &.active .step-icon {
          border-color: var(--green-light);
          background-color: var(--green-dark);
          color: var(--white);
        }
        
        // 2. Previously completed steps
        &.before.completed .step-icon {
          border-color: var(--green-light);
          background-color: var(--green-light);
          color: var(--white);
        }
        
        // 3. Previous incomplete steps
        &.before:not(.completed) .step-icon {
          border-color: var(--green-light);
          background-color: var(--background-primary);
          color: var(--color-disabled);
        }
        
        // 4. Upcoming incomplete steps
        &.after:not(.completed) .step-icon {
          border-color: var(--background-primary);
          background-color: var(--background-primary);
          color: var(--color-disabled);
        }
        
        // 5. Upcoming completed steps
        &.after.completed .step-icon {
          border-color: var(--background-tertiary);
          background-color: var(--background-tertiary);
          color: var(--white);
        }
        
        // Disabled state - remove all hover effects
        &.disabled {
          opacity: 0.5;
          cursor: default;
          pointer-events: none; // This will completely disable hover effects
        }
        
        // Only apply hover effects to non-disabled steps
        &:not(.disabled):hover:not(.active) {
          .step-icon {
            border-color: var(--color-secondary);
            transform: scale(1.05);
          }
          
          .step-label {
            color: var(--color-secondary);
          }
        }
        
        // Transition for hover effects
        .step-icon {
          transition: all 0.2s ease;
        }
      }
      
      .step-connector {
        flex: 1;
        height: 3px;
        width: 64px;
        margin: 0px calc(-1 * var(--unit-lg));
        margin-top: 20px;
        position: relative;
        z-index: 0;
        
        &.before {
          background-color: var(--green-light);
        }
        
        &.after {
          background-color: var(--background-primary);
        }
      }
      
      a.step {
        text-decoration: none;
        color: inherit;
      }
    }
  }
  
  // Fade transitions between steps
  .name-step, .prompt-step, .connections-step {
    animation: pop-in-small 0.1s ease-in-out;
  }
  
  &.loading {
    justify-content: center;
    align-items: center;
    
    .loading-text {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-disabled);
      svg {
        width: var(--form-sm);
        height: var(--form-sm);
        animation: loading-spin 1.0s ease-in-out infinite;
      }
    }
  }

  .finish-step {
    animation: agent-created 0.3s cubic-bezier(.2,.77,.59,1.58);
  }

}
