[data-component="AboutPage"] {
  position: relative;
  
  .about-container {
    max-width: var(--screen-md);
    margin: 0 auto;
    padding: var(--unit-md) var(--unit-xl);
    overflow: hidden;
  }
  
  .about-section {
    margin-bottom: calc(var(--unit-xl) * 1.5);
    text-align: center;
    
    h1 {
      font-size: var(--font-size-xxl);
      margin-top: 0px;
      margin-bottom: var(--unit-lg);
      font-weight: var(--font-weight-medium);
    }
    
    .about-description {
      font-size: var(--font-size-lg);
      color: var(--color-secondary);
      max-width: 800px;
      margin: 0px auto;
      line-height: 1.4;
    }
  }
  
  .team-section, .company-section {
    margin-bottom: calc(var(--unit-xl) * 1.5);
    text-align: center;
    
    h2 {
      font-size: var(--font-size-xl);
      margin-bottom: var(--unit-xl);
      position: relative;
      font-weight: var(--font-weight-medium);
      
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 60px;
        height: 2px;
        background-color: var(--outline-default);
      }
    }
  }
  
  .team-section {
    display: flex;
    flex-wrap: wrap;
    padding: var(--unit-xl);
    gap: var(--unit-xl);
    justify-content: center;
  }
  
  .company-section {
    p {
      font-size: var(--font-size-md);
      margin-bottom: var(--unit-md);
      color: var(--color-secondary);
    }
  }
}