[data-component="DiscordLinkCard"] {

  width: 100%;
  height: 100%;
  position: relative;
  background: var(--background-primary);
  border-radius: var(--radius-lg);
  overflow: hidden;

  display: flex;
  flex-direction: column;

  .card-banner {
    width: 100%;
    height: 80px;
    background: var(--background-tertiary);
    position: relative;
    overflow: hidden;
    
    img, .banner-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--unit-md);
    padding: var(--unit-lg);
    padding-top: calc(32px);
    flex-grow: 1;

    .card-avatar {
      border: var(--unit-sm) solid var(--background-primary);
      position: absolute;
      left: var(--unit-md);
      top: -32px;
      width: 64px;
      height: 64px;
      border-radius: 1000px;
      background: var(--background-tertiary);
      overflow: hidden; 
      img, .avatar-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-size: var(--font-size-md);
      }
      .avatar-placeholder {
        background: var(--blurple);
      }
    }

    .card-title {
      gap: var(--unit-md);
      font-size: var(--font-size-xl);
      
      a {
        margin-left: var(--unit-md);
        svg {
          width: calc(var(--unit-lg));
          height: calc(var(--unit-lg));
        }
      }
    }

    .card-agent {
      display: flex;
      align-items: center;
      gap: var(--unit-md);
      margin-top: auto;
      svg {
        width: var(--unit-lg);
        height: var(--unit-lg);
        color: var(--color-disabled);
      }
      .card-agent-avatar {
        width: var(--unit-xl);
        height: var(--unit-xl);
        border-radius: 1000px;
        background: var(--background-tertiary);
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .card-agent-name {
        font-size: var(--font-size-md);
      }
      .no-agent {
        color: var(--color-disabled);
      }
    }

  }

  .actions {
    position: absolute;
    top: var(--unit-lg);
    right: var(--unit-lg);
    z-index: 2;
    .linked {
      display: flex;
      align-items: center;
      gap: var(--unit-sm);
      background: var(--background-primary);
      padding: var(--unit-sm) var(--unit-md);
      border-radius: var(--radius-lg);
      svg {
        width: var(--unit-lg);
        height: var(--unit-lg);
        color: var(--green-light);
        path {
          fill: var(--blurple);
        }
      }
    }
  }

}