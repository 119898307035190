[data-component="Textbox"] {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-md);
  color: var(--color-primary);
  &[data-type="chat"] {
    textarea, input {
      border-radius: calc(var(--form-md) / 2);
      box-shadow: var(--shadow-dark-small);
      padding-right: var(--form-md);
    }
  }
  .textbox-container:has(input:placeholder-shown),
  .textbox-container:has(textarea:placeholder-shown) {
    .end-icon .send-icon-container {
      background: var(--background-tertiary) !important;
      pointer-events: none;
      .send-icon {
        color: var(--color-disabled) !important;
      }
    }
  }
  &.error {
    textarea, input {
      border-color: var(--red-text) !important;
    }
  }
  .heading {
    font-size: var(--font-size-sm);
    padding: var(--unit-sm);
    display: flex;
    align-items: flex-end;
    height: var(--unit-xl);
    font-weight: var(--font-weight-medium);
  }
  .error-text {
    margin: 0px 1px;
    padding: 0px var(--unit-lg);
    font-size: var(--font-size-sm);
    color: var(--red-text);
  }
  textarea, input {
    caret-color: var(--blue-main);
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    width: 100%;
    padding: var(--unit-md) var(--unit-lg);
    font-family: var(--font-family);
    background-color: var(--background-primary);
    line-height: calc(var(--form-md) - var(--unit-md) * 2 - 2px);
    min-height: var(--form-md);
    border-radius: var(--radius-lg);
    border: 1px solid var(--outline-default);
    outline: none;
    resize: none;
    transition: border-color 0.1s ease-in-out;
    &:hover {
      border-color: var(--outline-hover);
    }
    &:focus {
      border-color: var(--outline-hover);
      outline: none;
    }
    &.monospace {
      font-family: "Source Code Pro", Menlo, Monaco, Consolas, monospace;
      font-size: var(--font-size-sm);
      letter-spacing: -0.2px;
    }
    &[readonly] {
      cursor: default;
      color: var(--color-disabled);
      &:hover, &:focus {
        border-color: var(--outline-default);
      }
    }
    &[disabled] {
      cursor: default;
      color: var(--color-disabled);
      border-color: transparent;
      &:hover, &:focus {
        border-color: transparent;
      }
    }
  }
  .textbox-container {
    position: relative;
    display: flex;
    align-items: center;
    .end-icon, .start-icon {
      position: absolute;
      pointer-events: none;
      z-index: 1;
      right: 0px;
      height: var(--form-md);
      bottom: 0px;
      display: flex;
      align-items: center;
      padding: 0px var(--unit-md);
      gap: var(--unit-md);
      svg {
        border-radius: var(--radius-lg);
        color: inherit;
        width: 32px;
        height: 32px;
        padding: var(--unit-md);
        stroke-width: 2;
        color: var(--color-disabled);
      }
    }
    .end-icon:has(.clear-icon) {
      pointer-events: all;
      .clear-icon {
        cursor: pointer;
        &:hover {
          background-color: var(--background-tertiary);
        }
      }
    }
    .end-icon:has(.send-icon-container) {
      pointer-events: all;
      .send-icon-container {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--blue-main);
        color: var(--white);
        border-radius: 1000px;
        transition: transform 0.1s ease-in-out;
        .send-icon {
          color: var(--white);
        }
        &:hover {
          transform: translateY(-1px);
        }
      }
    }
    .start-icon {
      left: 0px;
      right: auto;
      background-color: transparent;
    }
    &:has(.start-icon) {
      input, textarea {
        padding-left: var(--form-md);
      }
    }
  }
  &[data-size="small"] {
    font-size: var(--font-size-sm);
    textarea, input {
      line-height: calc(var(--form-sm) - 2px);
      min-height: var(--form-sm);
      padding: 0px var(--unit-md);
    }
    &:has(.start-icon) {
      input, textarea {
        padding-left: calc(var(--form-sm) - var(--unit-sm));
      }
    }
    .textbox-container .end-icon,
    .textbox-container .start-icon {
      height: var(--form-sm);
      svg {
        padding: 1px;
        width: 16px;
        height: 16px;
      }
    }
    .textbox-container .end-icon:has(.send-icon-container) {
      svg {
        padding: 3px;
      }
    }
  }
  &:not([data-click-to-edit="false"]) {
    textarea, input {
      cursor: text;
      padding-right: var(--form-md);
    }
    textarea, input {
      color: var(--color-primary);
      cursor: pointer !important;
      border-color: transparent;
      background-color: transparent;
    }
    &:hover {
      textarea, input {
        border-color: var(--outline-default);
      }
    }
    .textbox-container .end-icon,
    .textbox-container .start-icon {
      pointer-events: auto;
      svg.edit-icon {
        border: 1px solid var(--outline-default);
        cursor: pointer;
        background-color: var(--background-primary);
        &:hover {
          border-color: var(--outline-hover);
          color: var(--color-primary);
        }
        &:active {
          border-color: var(--outline-active);
        }
      }
    }
    &[data-is-editing="true"] {
      textarea, input {
        border-color: var(--outline-active);
      }
    }
  }
}