[data-component="Button"] {
  position: relative;
  max-width: 100%;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  transform: translateY(-2px); // Compensate for special border
  user-select: none;
  transition: transform 0.1s ease-in-out;
  // Compensate for special border
  margin-top: 2px;
  margin-left: -3px;
  margin-right: -1px;

  &.no-icon {
    button {
      .prefix {
        display: none;
      }
      .postfix {
        display: none;
      }
      .label {
        padding-left: var(--unit-lg);
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
    }
    &[data-size="small"] {
      button {
        .label {
          padding-left: var(--unit-md);
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
      }
    }
  }

  .hotkeys {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--unit-sm);
    background: var(--background-tertiary);
    border: 1px solid var(--outline-default);
    border-radius: var(--radius);
    font-size: var(--font-size-xs);
    margin-right: var(--unit-sm);
    line-height: 12px;
    padding: var(--unit-sm) var(--unit-md);
    opacity: 0.6;
    pointer-events: none;
    & > svg {
      width: 10px;
      height: 10px;
      stroke-width: 2px;
    }
    & > span, & > svg {
      font-family: var(--font-family-monospace);
    }
  }

  button {
    cursor: pointer;
    padding: 0px;
    color: var(--color-primary);
    font-size: var(--font-size-md);
    border-radius: var(--radius-lg);
    margin: 0px 3px;
    outline: none;
    resize: none;
    transform: translateY(0px);
    height: calc(var(--form-md) - 2px);
    display: inline-flex;
    font-family: var(--font-family);
    background-color: transparent;
    border: none;
    position: relative;
    &:before {
      content: '';
      display: block;
      background: var(--background-primary);
      border: 1px solid var(--outline-default);
      border-radius: var(--radius-lg);
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: border-color 0.1s ease-in-out;
    }
    .special-border {
      display: none;
      background: var(--red-main);
      border-radius: var(--radius-lg);
      position: absolute;
      top: -2px;
      left: -2px;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      z-index: 0;
      overflow: hidden;
      .overflow {
        position: absolute;
        z-index: 0;
        top: -500%;
        left: -500%;
        right: -500%;
        bottom: -500%;
        animation: loading-spin 60s linear infinite;
        background: conic-gradient(
          red, 
          orange, 
          yellow, 
          green, 
          cyan, 
          blue, 
          violet,
          red
        );
      }
    }
    &:hover {
      &:before {
        border-color: var(--outline-hover);
      }
      transform: translateY(-1px);
    }
    &:focus {
      &:before {
        border-color: var(--outline-active);
      }
    }
    &:active {
      transform: translateY(1px);
    }
    & > a, & > a:link, & > a:active, & > a:focus, & > a:hover,
    & > span.anchor, & > span.anchor:hover {
      color: inherit;
      text-decoration: none;
      z-index: 2;
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;
    }
    .prefix, .postfix {
      display: flex;
      position: relative;
      height: 100%;
      align-items: center;
      justify-content: center;
      svg {
        color: inherit;
        width: 18px;
        height: 18px;
        stroke-width: 3px;
      }
    }
    .prefix {
      padding-left: var(--unit-lg);
      margin-right: calc(0px - var(--unit-md));
    }
    .postfix {
      padding-right: var(--unit-lg);
      margin-right: calc(0px - var(--unit-md));
    }
    .label {
      display: flex;
      height: 100%;
      align-items: center;
      padding: 0px var(--unit-lg);
    }
    &[disabled] {
      pointer-events: none;
      border-color: transparent !important;
      color: var(--color-disabled) !important;
      &:before {
        background: var(--background-primary) !important;
        border-color: var(--outline-default) !important;
      }
      &:hover, &:focus, &:active {
        border-color: transparent !important;
        transform: translateY(0px) !important;
      }
    }
  }

  &.postfix {
    .prefix {
      left: auto;
      right: 0px;
    }
    .label {
      margin-left: 0px;
      margin-right: calc(var(--form-md) - var(--unit-md));
    }
  }

  &[data-size="small"] {
    button {
      font-size: var(--font-size-sm);
      height: calc(var(--form-sm) - 2px);
      .prefix, .postfix {
        svg {
          width: 12px;
          height: 12px;
          stroke-width: 2px;
        }
      }
      .label {
        padding: 0px var(--unit-md);
      }
      .prefix {
        padding-left: var(--unit-md);
        margin-right: calc(0px - var(--unit-sm));
      }
      .postfix {
        padding-right: var(--unit-md);
        margin-right: calc(0px - var(--unit-sm));
      }
    }
  }

  &[data-size="large"] {
    button {
      font-size: var(--font-size-xl);
      height: var(--form-lg);
      .prefix, .postfix {
        svg {
          width: 24px;
          height: 24px;
        }
      }
      .label {
        padding: 0px var(--unit-lg);
      }
      .prefix {
        padding-left: var(--unit-lg);
        margin-right: calc(0px - var(--unit-md));
      }
      .postfix {
        padding-right: var(--unit-lg);
        margin-left: calc(0px - var(--unit-md));
      }
    }
  }

  &[data-color="special"] {
    button {
      margin: 0px 5px;
      &:before {
        border-color: transparent;
        background-color: var(--background-primary);
      }
      .special-border {
        display: block;
      }
      &:hover {
        .special-border {
          .overflow {
            animation: loading-spin 5s linear infinite;
          }
        }
      }
    }
  }

  &[data-color="blurple"] {
    button {
      &:before {
        background-color: var(--blurple);
      }
    }
  }

  &[data-color="green"] {
    button {
      &:before {
        background-color: var(--green-main);
      }
    }
  }

  &[data-color="blue"] {
    button {
      &:before {
        background-color: var(--blue-main);
      }
    }
  }

  &[data-color="orange"] {
    button {
      &:before {
        background-color: var(--orange-dark);
      }
    }
  }

  &[data-color="red"] {
    button {
      &:before {
        background-color: var(--red-dark);
      }
    }
  }

}