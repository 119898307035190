[data-component="PayloadEditor"] {
  position: absolute;
  right: var(--unit-md);
  bottom: var(--unit-md);
  max-width: calc(100vw - var(--unit-md) * 2);
  width: 300px;
  height: 200px;
  background: var(--background-secondary);
  border: 1px solid var(--background-tertiary);
  border-radius: var(--radius-lg);
  z-index: 100;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: var(--shadow-dark-medium);

  .payload-header {
    display: flex;
    padding: var(--unit-sm);
    font-size: var(--font-size-sm);
    gap: var(--unit-sm);
    align-items: center;
    flex-shrink: 0;

    &:first-child {
      padding-bottom: 0px;
    }

    &:last-child {
      padding-top: 0px;
    }

    & > span {
      padding: 0px var(--unit-sm);
    }
    
    .method-selector {
      flex-shrink: 0;
      flex-grow: 1;
    }
    
    .spacer {
      flex-grow: 1;
    }
    
    .payload-actions {
      display: flex;
      gap: var(--unit-sm);
    }
  }
  
  .payload-editor {
    flex: 1 1 auto;
    display: flex;
    min-height: 0; /* Important for Firefox flex container */
    padding: var(--unit-sm);
    position: relative;
    [data-component="MiniEditor"] {
      border-radius: var(--radius-lg);
      overflow: hidden;
      control[control="CPHEditor"] {
        border-color: transparent;
        &.focus {
          border-color: var(--outline-hover);
        }
      }
    }
  }

  .payload-error {
    font-size: var(--font-size-sm);
    padding: var(--unit-sm);
    margin: var(--unit-sm);
    margin-top: 0px;
    background: var(--red-xdark);
    color: var(--color-primary);
    border-radius: var(--radius-lg);
  }

}