[data-component="PackagesPage"] {

  padding: var(--unit-md) var(--unit-lg);
  margin-bottom: calc(var(--unit-xl) * 2);

  .packages-title {
    font-size: var(--font-size-xxl);
    font-weight: var(--font-weight-medium);
    margin-bottom: calc(var(--unit-lg) * 3);
    text-align: center;

    .subheading {
      font-weight: normal;
      font-size: var(--font-size-lg);
      margin-top: var(--unit-lg);
      color: var(--color-secondary);
    }
  }

  .packages-list {
    max-width: var(--screen-sm);
    margin: 0px auto;
    & > [data-component] {
      margin: var(--unit-md) 0px;
    }
  }

}