[data-component="AgentCard"] {

  display: flex;
  text-decoration: none !important;
  color: var(--color-primary);

  .agent-card-container {
    width: calc(100% - var(--unit-md) * 2);
    margin: 0px var(--unit-md);
    border-radius: var(--radius-lg);
    display: flex;
    .avatar {
      flex-shrink: 0;
      flex-grow: 0;
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      padding: var(--unit-lg);
      img {
        border-radius: 100px;
        background: var(--outline-active);
        width: var(--form-sm);
        height: var(--form-sm);
        object-fit: cover;
      }
    }
  
    .conversation-info {
      flex-grow: 1;
      padding: var(--unit-md) 0px;
      border-bottom: 1px solid var(--background-tertiary);
      margin-right: var(--unit-md);
      overflow: hidden;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: var(--font-size-sm);
        gap: var(--unit-sm);
        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: var(--font-weight-medium);
          color: var(--color-primary);
        }
        .timestamp {
          flex-shrink: 0;
          color: var(--color-disabled);
        }
      }
      .description {
        font-size: var(--font-size-sm);
        color: var(--color-secondary);
        overflow: hidden;
        .message {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .servers {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: var(--unit-md);
          color: var(--color-disabled);
          margin-top: var(--unit-md);
          svg {
            color: var(--blurple);
            width: calc(var(--unit-md) + var(--unit-sm));
            height: calc(var(--unit-md) + var(--unit-sm));
            path {
              fill: currentColor;
            }
          }
        }
      }
    }
  }

  &:hover:not(.selected) {
    .agent-card-container {
      background: var(--background-tertiary);
      .conversation-info {
        border-color: transparent;
      }
    }
  }

  &.selected {
    .agent-card-container {
      background: var(--blue-dark);
      .conversation-info {
        border-color: transparent;
        .name, .timestamp, .description {
          color: var(--color-primary);
        }
        .timestamp, .description .message {
          opacity: 0.8;
        }
        .description .servers {
          color: var(--color-primary);
          span {
            opacity: 0.8;
          }
          svg path {
            fill: var(--color-primary);
          }
        }
      }
    }
  }

}