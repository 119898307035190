[data-component="EditProfilePage"] {

  position: relative;
  max-width: var(--screen-md);
  width: 100%;
  padding: var(--unit-xl) calc(var(--unit-xl) * 2);

  @media screen and (max-width:567px) {
    padding: var(--unit-xl) var(--unit-lg);  
  }

  .profile-edit {
    margin: 0px auto;
    width: 100%;

    .profile-header {
      .profile-name {
        font-size: var(--font-size-xxl);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: var(--unit-sm);

        .name {
          margin-right: var(--unit-md);
        }
        .verified {
          display: flex;
          align-items: center;
          position: relative;

          &:hover .popover {
            opacity: 1;
          }

          .popover {
            position: absolute;
            left: calc(100% + var(--unit-md));
            top: 50%;
            background: var(--background-primary);
            border-radius: var(--radius-lg);
            font-size: var(--font-size-sm);
            padding: var(--unit-md);
            width: 170px;
            transform: translateY(-50%);
            pointer-events: none;
            opacity: 0;
            transition: opacity 200ms ease-in-out;
          }
          img {
            width: calc(var(--unit-lg) + var(--unit-md));
            height: calc(var(--unit-lg) + var(--unit-md));
            margin-right: var(--unit-md);
          }

          svg {
            opacity: 0.25;
            width: calc(var(--unit-lg) + var(--unit-sm));
            height: calc(var(--unit-lg) + var(--unit-sm));
          }
        }
      }
      .profile-details {
        font-size: var(--font-size-md);
        display: flex;
        align-items: center;
        gap: var(--unit-md);
        .email {
          color: var(--color-tertiary);
          font-size: var(--font-size-md);
        }
      }
    }

    .profile-row {
      display: flex;
      flex-wrap: wrap;
      gap: var(--unit-md);
      margin-top: calc(var(--unit-xl) + var(--unit-lg));

      @media screen and (max-width: 567px) {
        flex-direction: column;
      }

      .profile-title {
        font-weight: var(--font-weight-medium);
        padding-right: var(--unit-md);
        width: 200px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        font-size: var(--font-size-lg);
        .description {
          font-weight: var(--font-weight-normal);
          color: var(--color-tertiary);
          font-size: var(--font-size-md);
        }
      }

      .profile-entry {
        flex-grow: 1;
      }
    }

    .profile-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: var(--unit-xl);
    }

  }

}