[data-component="PageLoading"] {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  z-index: 9999;
  gap: var(--unit-xl);
  opacity: 0;
  animation: fade-in 0.2s ease-in 0.2s forwards;

  svg {
    width: var(--form-sm);
    height: var(--form-sm);
    color: var(--color-disabled);
    animation: loading-spin 1s ease-in-out infinite;
  }
  img {
    width: 128px;
    max-width: calc(100% - var(--unit-xl));
  }

}

[data-component="PageNavigating"] {

  position: absolute;
  flex-grow: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 0px;
  z-index: 9999;
  pointer-events: none;
  background: transparent;
  overflow: visible;

  .navigation-loader {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 2px;
    width: 0%;
    background: var(--blue-main);
    opacity: 0;
    animation: fade-in 0.2s ease-in 0.2s forwards;
  }
  
}