[data-component="AgentsSettings"] {
  background: var(--background-secondary);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .agents-error {
    margin-top: var(--unit-lg);
    padding: 0px var(--unit-lg);
    [data-component="ContentButton"] {
      margin-right: calc(-1 * var(--unit-md));
    }
  }

  .settings {
    flex-shrink: 0;
    padding: var(--unit-lg);
    padding-bottom: var(--unit-md);
    position: relative;

    &:after {
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0px;
      height: 1px;
      width: 100%;
      content: '';
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        var(--outline-hover) 40%,
        var(--outline-hover) 60%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    .edit-avatar {
      margin: calc(-1 * var(--unit-lg));
      padding: var(--unit-lg) var(--unit-xl);
      position: relative;
      display: flex;
      align-items: flex-end;
      gap: var(--unit-md);
    }

    .agent-name {
      margin-top: var(--unit-md);
      [data-component="Textbox"] {
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-xl) !important;
        input, textarea {
          line-height: var(--font-size-xl);
        }
      }
    }
  }

  .agent-scroll-container {
    overflow: auto;
    position: relative;
    flex-grow: 1;
    padding: var(--unit-lg);
    padding-top: var(--unit-md);

    .agent-prompt {
      margin-top: var(--unit-md);
      border-radius: var(--radius-lg);
      .agent-prompt-heading {
        border: 1px solid transparent; // for alignment
        // margin: 0px var(--unit-md);
        padding: var(--unit-lg);
        padding-bottom: var(--unit-md);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-medium);
        display: flex;
        align-items: center;
        gap: var(--unit-md);
        svg {
          width: var(--unit-lg);
          height: var(--unit-lg);
          color: var(--color-disabled);
        }
      }
      [data-component="Textbox"] {
        textarea, input {
          color: var(--color-secondary);
        }
      }
    }

    .agent-settings-row {
      display: flex;
      align-items: center;
      font-size: var(--font-size-sm);
      padding-left: var(--unit-md);

      .settings-label {
        width: 128px;
        flex-shrink: 0;
        color: var(--color-primary);
        display: flex;
        align-items: center;
        gap: var(--unit-md);
        svg {
          width: var(--unit-lg);
          height: var(--unit-lg);
          color: var(--color-disabled);
        }
        &.settings-enabled {
          svg {
            color: var(--green-light);
          }
        }
        &.settings-disabled {
          svg {
            color: var(--red-main);
          }
        }
      }

      .settings-button {
        display: flex;
        align-items: center;
      }
    }

    .connections-section {
      margin-top: var(--unit-md);
      .connections-section-heading {
        border: 1px solid transparent; // for alignment
        padding: var(--unit-lg);
        padding-bottom: var(--unit-md);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-medium);
        display: flex;
        align-items: center;
        gap: var(--unit-md);
        svg {
          width: var(--unit-lg);
          height: var(--unit-lg);
          color: var(--color-disabled);
        }
      }

      .discord-links {
        display: block;
        padding: 0px var(--unit-md);
        margin-top: calc(var(--unit-md) + var(--unit-sm));
        .discord-link-entry {
          display: flex;
          align-items: center;
          color: var(--color-secondary);
          margin: var(--unit-md) calc(var(--unit-md) * -1);
          gap: var(--unit-md);
          background: var(--background-primary);
          padding: var(--unit-md) var(--unit-md) var(--unit-md) var(--unit-lg);
          border-radius: var(--radius-lg);
          strong {
            font-weight: var(--font-weight-normal);
            color: var(--color-primary);
          }
          .icon {
            &.avatar {
              width: 32px;
              height: 32px;
              border-radius: 1000px;
              background: var(--blurple);
              text-align: center;
              color: white;
              justify-content: center;
              font-size: var(--font-size-sm);
              overflow: hidden;
            }
            svg {
              width: var(--unit-lg);
              height: var(--unit-lg);
              margin: auto;
            }

            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
              object-position: center;
            }
          }
          .info {
            svg {
              width: 12px;
              height: 12px;
            }
          }
          & > * {
            display: flex;
            align-items: center;
          }
          .warn {
            font-size: var(--font-size-sm);
            color: var(--orange-main);
          }
        }
      }

      .connections-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }

  }

  .actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding: var(--unit-lg);
    position: relative;
    gap: var(--unit-sm);
    &:before {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 1px;
      width: 100%;
      content: '';
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        var(--outline-hover) 40%,
        var(--outline-hover) 60%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

}