[data-component="CodeEditor"],
[data-component="MiniEditor"] {

  control[control="CPHEditor"] {

    .editor textarea,
    .editor div.render {
      font-family: var(--font-family-monospace) !important;
    }

    &:not([data-maximized]) {
      border: 1px solid transparent;
      border-radius: var(--radius-lg);
      overflow: hidden;
      &.focus {
        border-color: transparent;
      }
      .editor .read-only {
        display: none;
      }
    }

    .empty, .loading {
      font-family: var(--font-family) !important;
      font-size: var(--font-size-md);
      color: var(--color-disabled);
      background-color: var(--background-primary);
    }

    .editor {
      .line-container {
        .line-numbers div.number {
          color: var(--color-disabled);
          opacity: 0.5;
          &.selected {
            color: var(--color-primary);
          }
        }
      }
      .edit-text {
        color: var(--color-primary);
        .scrollbar .scroller {
          &:before {
            background-color: var(--color-disabled);
            opacity: 0.2;
          }
          &:hover:before {
            opacity: 0.3;
          }
        }
      }
      div.render div.line div.display span.suggestion {
        color: var(--color-disabled);
        font-style: italic;
      }
    }

    .file {
      background: var(--background-primary);
      img, iframe {
        background-image: repeating-conic-gradient(rgba(255, 255, 255, 0.2) 0% 25%, rgba(0, 0, 0, 0.2) 0% 50%);
        background-position: 0 0, 8px 8px;
        background-size: 16px 16px;
      }
    }

    .editor {
      background: var(--background-primary);

      .read-only {
        margin-top: var(--unit-lg);
        margin-right: var(--unit-lg);
        background: var(--background-tertiary);
      }

      .render .line .display .whitespace {
        color: rgba(255, 255, 255, 0.2);
      }

      .render .line .complement .underline {
        border-bottom-color: var(--blue-main);
      }
      
      .render .line .selection .border {
        color: var(--blue-main);
        background-color: rgba(64, 128, 255, 0.4);
      }

      .render .line .selection .border.error {
        color: var(--red-main);
        background-color: rgba(255, 0, 0, 0.4);
      }

      .render .line .line-selection.highlight {
        background-color: var(--white);
        opacity: 0.1;
      }

      div.render div.line div.find span.found::before {
        background-color: transparent;
        border-color: var(--orange-main);
        opacity: 1.0;
      }

    }

    .edit-text {
      background: var(--background-primary);

      .hljs-bullet,
      .hljs-quote,
      .hljs-regexp,
      .hljs-link,
      .hljs-keyword,
      .hljs-name {
        color: var(--purple-light); 
      }

      .hljs-doctag {
        color: var(--blue-main);
      }

      .hljs-tag {
        color: var(--color-disabled);

        .hljs-name {
          color: var(--red-main); 
        }

        .hljs-attr {
          color: var(--blue-light); 
        }

        .hljs-string {
          color: var(--orange-light); 
        }
      }

      .hljs-code,
      .hljs-section,
      .hljs-string,
      .hljs-html {
        color: var(--blue-main); 
      }

      .hljs-strong {
        font-weight: var(--font-weight-bold);
      }

      .hljs-comment {
        color: var(--color-disabled);
      }

      .hljs-keyword {
        color: #ff6363;
      }

      .hljs-function {
        .hljs-keyword {
          color: var(--purple-light);
        }
      }

      .hljs-title {
        color: var(--blue-main);
      }

      .hljs-params,
      .hljs-selector-pseudo {
        color: var(--orange-main);
      }

      .hljs-string {
        color: var(--green-light);
      }

      .hljs-subst,
      .hljs-built_in {
        color: var(--blue-light);
      }

      .hljs-literal {
        color: var(--blue-main);
      }
      
      .hljs-number {
        color: #ffdb64;
      }

      .hljs-selector-tag {
        color: #ff6363;
        // color: var(--red-main);
      }

      .hljs-selector-class,
      .hljs-selector-id {
        color: var(--blue-main);
      }

      .hljs-attribute {
        color: var(--purple-light);
      }
    }

  }
            
  control[control="CPHTreeView"] {

    font-family: var(--font-family);
    font-size: var(--font-size-sm);

    .files {
      padding: var(--unit-md) var(--unit-md);

      .temporary {
        color: var(--color-disabled);
        .icon {
          width: var(--unit-lg);
          height: var(--unit-lg);
          color: var(--background-tertiary);
          margin-right: var(--unit-sm);
        }
      }

      .directory-group[data-drop="true"] {
        & > .file {
          background: transparent !important;
        }
        & > .directory {
          background: var(--blue-dark) !important;
        }
      }

      .directory-group {
        &[data-selected] {
          & > .directory {
            color: var(--color-primary);
            .icon {
              color: inherit;
            }
          }
        }
        & > .file,
        & > .directory {
          color: var(--color-tertiary);
          padding: calc(var(--unit-md) + 2px);

          .icon {
            width: var(--unit-lg);
            height: var(--unit-lg);
            color: inherit;
            margin-right: var(--unit-sm);
            opacity: 0.5;
            &.folder {
              color: var(--blue-main) !important;
              opacity: 1.0;
              &.green {
                color: var(--green-light) !important;
              }
              &.red {
                color: var(--red-main) !important;
              }
              &.blue {
                color: var(--blue-main) !important;
              }
              &.orange {
                color: var(--orange-main) !important;
              }
              &.grey {
                color: var(--color-disabled) !important;
              }
            }
          }

          &.modified {
            color: var(--orange-main) !important;
            .icon > svg {
              color: var(--orange-main) !important;
            }
          }

          &.highlight {
            background: var(--blue-dark) !important;
            color: var(--color-primary) !important;
          }

          &:hover {
            background: var(--background-secondary);
            color: var(--color-primary);
          }

          &[data-selected] {
            background: var(--background-tertiary);
            color: var(--color-primary);
          }
        }
      }
    }
  }

  control[control="CPHFileTabs"] {
    border-bottom: 1px solid var(--background-secondary);

    .file-tabs {

      .files {

        .separator {
          background-color: var(--background-tertiary);
          &.highlighted {
            background-color: var(--blue-main);
          }
        }

        .file {
          font-family: var(--font-family);
          background: var(--background-primary);
          font-size: var(--font-size-sm);
          color: var(--color-disabled);
          border-color: transparent;
          border-bottom-color: var(--background-secondary);

          .close {
            opacity: 0;
            color: var(--color-primary);
            transition: color 0.1s ease-in-out,
              opacity 0.1s ease-in-out,
              background 0.1s ease-in-out,
              transform 0.1s ease-in-out;
            &:before, &:after {
              background-color: currentColor;
              opacity: 1.0;
            }
            &:hover {
              background: transparent;
              opacity: 0.8 !important;
            }   
          }

          &.modified {
            .close {
              background-color: var(--orange-main);
              color: var(--orange-main);
              opacity: 1.0 !important;
              &:hover {
                &:before, &:after {
                  background-color: var(--color-primary);
                  opacity: 1.0;
                }
              }
            }
          }

          &[data-selected] {
            background: var(--background-primary);
            color: var(--color-primary);
            border-color: var(--background-secondary);
            font-weight: var(--font-weight-medium);
            border-bottom-color: var(--background-primary);
            .close {
              opacity: 0.4;
            }
          }

          &:hover:not([data-selected]) {
            background: var(--background-secondary);
            .close {
              opacity: 0.4;
            }
          }

          &.new-file {
            color: var(--color-primary);
            border-bottom: 1px solid var(--background-secondary);
          }
        }
      }

    }
  }

  @at-root {

    .treeview-dragging {
      font-family: var(--font-family);
      font-size: var(--font-size-md) !important;
      background-color: var(--background-tertiary) !important;
      color: var(--color-primary) !important;
      opacity: 1.0 !important;
      & > span.icon {
        opacity: 0.6;
        &.folder {
          opacity: 1.0;
          color: var(--blue-main) !important;
        }
        &.copy,
        &.move {
          opacity: 1.0;
          color: var(--green-light) !important;
        }
        &.orange {
          color: var(--orange-main) !important;
        }
      }
    }

    div.file.file-tabs-dragging {
      font-family: var(--font-family);
      font-size: var(--font-size-sm);
      background-color: var(--background-tertiary) !important;
      color: var(--white) !important;
      opacity: 0.4 !important;
    
      .close {
        color: var(--white) !important;
      }
    }

    control[control="CPHConfirm"],
    control[control="CPHTextInput"] {
      div.modal {
        font-family: var(--font-family);
        font-size: var(--font-size-sm) !important;
        background: var(--background-secondary);
        border: 1px solid var(--background-tertiary);
        color: var(--white);

        .input {
          input[type="text"] {
            font-size: var(--font-size-sm) !important;
            background-color: var(--background-primary) !important;
            color: var(--color-primary) !important;
            border-radius: var(--radius) !important;
            border-color: var(--outline-default) !important;
            &:focus {
              border-color: var(--outline-active) !important;
            }
          }
          .btn {
            font-size: var(--font-size-sm) !important;
            font-family: var(--font-family);
            background-color: var(--blue-dark) !important;
            border-color: transparent !important;
            &[name="cancel"] {
              background-color: var(--background-primary) !important;
              color: var(--color-primary) !important;
            }
            &:hover {
              border-color: transparent !important;
              transform: translateY(-1px) !important;
            }
            &:active {
              transform: translateY(1px) !important;
            }
            &:focus {
              outline: none !important;
            }
          }
        }
        .error {
          color: var(--red-main) !important;
        }
      }
    }

    control[control="CPHContextMenu"] {
      background-color: var(--background-secondary);
      border: 1px solid var(--background-tertiary);
      color: var(--color-disabled);
      padding: var(--unit-md);
      min-width: 170px;
      border-radius: var(--radius-lg);
      font-family: var(--font-family);
      font-size: var(--font-size-sm);

      hr {
        background-color: var(--color-primary);
        opacity: 0.1;
        margin: var(--unit-md) 0;
      }

      .item {
        color: var(--color-primary);  
        height: calc(var(--unit-lg) * 2);

        &.disabled {
          opacity: 0.2;
        }

        .title {
          svg {
            color: var(--color-tertiary);
            opacity: 0.5 !important;
            width: 16px;
            height: 16px;
          }
        }
      }
    }  
  }

  control[control="CPHFindReplace"] {
    background-color: var(--background-secondary);
    border: 1px solid var(--background-tertiary);
    font-size: var(--font-size-sm);
    div.row {
      input[type="text"] {
        font-family: var(--font-family);
        font-size: var(--font-size-sm);
        background-color: var(--background-primary);
        color: var(--color-primary);
        &:focus {
          border-color: var(--outline-active);
        }
        &::placeholder {
          color: var(--color-disabled);
        }
      }
      div.pos {
        font-family: var(--font-family);
        color: var(--color-primary);
      }
      a.btn {
        color: var(--color-primary);
        border-color: var(--outline-default);
        font-family: var(--font-family);
        &:hover {
          border-color: var(--outline-hover);
        }
        &[disabled] {
          color: var(--color-disabled);
          opacity: 0.5;
        }
      }
    }
  }

}