[data-component="TabList"] {
  display: block;
  position: sticky;
  z-index: 999;
  top: 0;
  width: 100%;
  &[data-overscroll-fix="true"] {
    position: fixed;
    & + [data-component="TabListSpacer"] {
      display: block;
    }
  }
  &[data-mode="page"] {
    &:before {
      z-index: 0;
      position: absolute;
      content: '';
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: var(--background-primary);
      opacity: 0.5;
    }
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    .tabs .tab.active {
      background: transparent;
    }
  }
  &[data-mode="app"] {
    &:before {
      z-index: 0;
      position: absolute;
      content: '';
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: var(--background-secondary);
      opacity: 0.5;
    }
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
  &[data-mode="chat"] {
    &:before {
      z-index: 0;
      position: absolute;
      content: '';
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: var(--background-primary);
      opacity: 1.0;
    }
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
  &[data-is-mobile="true"] {
    .tabs {
      gap: 0px;
      &.tabs-mobile {
        margin-right: calc(-1 * var(--unit-md));
      }
      &.shrink-tabs {
        .tab {
          &:not(.tab-end) {
            flex-shrink: 1;
          }
        }
      }
      &.main-tabs {
        .tab-first:not(.dropdown-header) {
          padding: 0px calc(var(--unit-sm) * 1.5); // fix for mobile chat view
        }
      }
      .tab {
        overflow: hidden;
        &.tab-end {
          height: calc(var(--form-md) - var(--unit-lg));
          margin: var(--unit-md) 0px;
          padding: 0px var(--unit-md);
          &.tab-first {
            margin-left: var(--unit-md);
          }
        }
        & > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .expander {
    width: 100%;
    height: calc(var(--form-md) + var(--unit-lg));
  }
  .container {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    display: flex;
  }
  .tabs {
    position: relative;
    display: flex;
    gap: var(--unit-md);
    padding: var(--unit-md);
    flex-shrink: 0;
    max-width: 100%;
    overflow: auto;
    align-items: center;
    z-index: 1;
    &.scrollable {
      overflow: auto;
      flex-shrink: 1;
      flex-grow: 1;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &.right {
      justify-content: flex-end;
      flex-grow: 1;
    }
  }

  .tab-border {
    border-radius: var(--radius-lg);
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    &:before {
      position: absolute;
      top: -500%;
      left: -500%;
      right: -500%;
      bottom: -500%;
      content: '';
      background: conic-gradient(
        red, 
        orange, 
        yellow, 
        green, 
        cyan, 
        blue, 
        violet,
        red
      );
      animation: loading-spin 60s linear infinite;
    }
    &:after {
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      content: '';
      background: var(--background-primary);
      border-radius: var(--radius-lg);
    }
  }

  .tab:hover {
    .tab-border:before {
      animation: loading-spin 5s linear infinite;
    }
  }

  .tab-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

[data-component="TabListSpacer"] {
  height: calc(var(--form-md) + var(--unit-lg));
  position: relative;
  flex-shrink: 0;
  display: none;
}

[data-component="TabList"],
.tab-dropdown-content-portal {

  .tab {
    position: relative;
    cursor: pointer;
    padding: 0px var(--unit-lg);
    border-radius: var(--radius-lg);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: var(--unit-md) 0px;
    height: var(--form-md);
    text-decoration: none;
    color: var(--color-disabled);
    gap: var(--unit-md);
    flex-shrink: 0;
    transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
    & > span {
      z-index: 2;
    }
    svg {
      width: var(--unit-lg);
      height: var(--unit-lg);
      stroke-width: 2;
      color: var(--color-disabled);
    }
    img {
      width: calc(var(--unit-lg) + var(--unit-md));
      height: calc(var(--unit-lg) + var(--unit-md));
      margin: 0px calc(-1 * (var(--unit-sm) * 1.5));
      border-radius: 1000px;
      & + div {
        padding-left: var(--unit-md);
      }
    }
    &:hover {
      color: var(--color-primary);
      background: var(--background-tertiary);
    }
    &.active {
      color: var(--color-primary);
      text-decoration: none !important;
      background: var(--background-primary);
    }
    &.signup {
      background: transparent;
      color: var(--color-primary);
      &:hover {
        background: var(--green-light);
      }
    }
    &.dropdown-header {
      padding: 0px;
      background: transparent;
      &:hover {
        background: transparent;
      }
      img {
        margin: 0px;
        margin-right: calc(-1 * (var(--unit-sm) * 1.5));
      }
    }
    &:hover, &.active {
      &.color-blue {
        svg {
          color: var(--blue-main);
        }
      }
      &.color-orange {
        svg {
          color: var(--orange-main);
        }
      }
      &.color-green {
        svg {
          color: var(--green-main);
        }
      }
    }
  }

}

.tab-dropdown-content-portal {
  background: var(--background-primary);
  border-radius: var(--radius-lg);
  border: 1px solid var(--outline-active);
  padding: var(--unit-md);
  box-shadow: var(--shadow-lg);
  z-index: 1000;
  position: fixed;
  // fade in
  opacity: 0;
  animation: fade-in 0.1s ease-in 0.05s forwards;
}