[data-component="CodeChat"] {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: var(--unit-md);

  .description {
    width: 100%;
    display: flex;
    gap: var(--unit-md);
    align-items: center;
    font-size: var(--font-size-md);
    color: var(--color-tertiary);
    padding: var(--unit-md);
    margin-bottom: var(--unit-md);
    margin-top: calc(-1 * var(--unit-md));
    position: relative;
    & > div {
      line-height: var(--form-sm);
    }
    &:before {
      display: block;
      position: absolute;
      bottom: 0px;
      max-width: 800px;
      left: 0px;
      right: 0px;
      height: 1px;
      margin: 0 auto;
      content: '';
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        var(--outline-hover) 40%,
        var(--outline-hover) 60%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
  
  mark {
    display: inline-block;
    font-weight: var(--font-weight-medium);
    background-color: var(--blue-main);
    color: var(--white);
    padding: var(--unit) var(--unit-sm);
    opacity: 0.8;
    border-radius: var(--radius);
    cursor: pointer;
    margin: var(--unit-sm);
    transition: opacity ease-in-out 0.1s;
    &.unclickable {
      cursor: inherit;
      opacity: 1.0;
      background: transparent;
      padding: 0px;
      margin: 0px;
      color: var(--color-primary);
      font-weight: var(--font-weight-normal);
    }
    &:hover {
      opacity: 1.0;
    }
  }

}

[data-component="CodeChatMessage"] {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--unit-md);
  margin-bottom: var(--unit-lg);
  border-radius: var(--radius-lg);
  // animation: message-pop-in 0.2s ease-in-out;
  
  .chat-body {
    display: flex;
    font-size: var(--font-size-md);
    color: var(--color-secondary);
    width: 100%;

    .chat-avatar {
      position: relative;
      display: flex !important;
      border-radius: 1000px;
      overflow: hidden;
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-sm);
      color: var(--white);
      width: calc(var(--unit-lg) * 1.5);
      height: calc(var(--unit-lg) * 1.5);
      border-radius: 1000px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-right: var(--unit-md);
      background: var(--green-main);
      &.app {
        background: var(--blue-main);
      }
      &.image {
        background: none;
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        border: none;
      }
    }

    .chat-content {
      display: flex !important;
      flex-direction: column;
      overflow: hidden;
      position: relative;
      width: 100%;

      .chat-author {
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
        .chat-author-name {
          color: var(--color-primary);
          display: flex !important;
          align-items: center;
          gap: var(--unit-md);
          margin-right: var(--unit-md);
          .app {
            background: var(--blue-main);
            padding: 0px var(--unit-sm);
            border-radius: var(--radius);
            font-weight: var(--font-weight-bold);
            font-size: var(--font-size-xs);
          }
        }
        .chat-time {
          color: var(--color-disabled);
          font-size: var(--font-size-sm);
          margin-right: var(--unit-md);
        }
      }
      
      .chat-events {
        display: flex;
        flex-direction: column;
        margin: var(--unit-sm) 0;
        width: 100%;
        
        .chat-event {
          width: 100%;
          font-family: var(--font-family-monospace);
          font-size: var(--font-size-sm);
          white-space: pre-wrap;
          word-break: break-word;
          display: flex;
          gap: var(--unit-md);
          &[data-event="@begin"],
          &[data-event="@info"],
          &[data-event="@stdout"] {
            color: var(--color-disabled);
          }
          &[data-event="@stderr"] {
            color: var(--orange-main);
          }
          &[data-event="@error"] {
            color: var(--red-main);
          }
          &[data-event="@response"] {
            display: none;
          }
          &[data-event="@result"],
          &[data-event="@error"] {
            [data-component="MiniEditor"] {
              margin-top: var(--unit-md);
            }
          }
          .chat-event-type {
            user-select: none;
            flex-shrink: 0;
          }
        }
      }

      .chat-message {
        overflow: auto;
        display: flex;
        flex-direction: column;
        line-height: var(--line-height-lg);
        white-space: pre-wrap;
        word-break: break-word;
        margin-top: var(--unit-md);

        & > p {
          margin: 0px;
          &:last-child {
            margin-bottom: 0px;
          }
        }

        pre:has(code) {
          margin: 0px;
          background: var(--background-primary);
          border-radius: var(--radius-lg);
          padding: var(--unit-md) var(--unit-lg);
          code {
            margin: 0px;
            padding: 0px;
            padding: var(--unit-sm) var(--unit-md);
          }
        }

        code {
          font-family: var(--font-family-monospace);
          font-size: var(--font-size-sm);
          background: var(--background-primary);
          border-radius: var(--radius-lg);
          padding: var(--unit) var(--unit-sm);
          border: 1px solid var(--outline-default);
          color: var(--color-tertiary);
          margin: 0px;
        }

        ul, ol {
          margin: var(--unit-md) 0px;
          padding: 0px;
          padding-left: var(--unit-xl);
          line-height: 0px;
          li {
            margin: 0px;
            padding: 0px;
            &:not(:last-child) {
              margin-bottom: var(--unit-md);
            }
          }
        }

        strong {
          font-weight: var(--font-weight-medium);
          color: var(--color-primary);
        }

        hr {
          max-width: 80%;
          min-width: calc(var(--unit-xl) * 4);
          border: none;
          border-top: 1px solid var(--outline-default);
          margin: var(--unit-lg) auto;
        }

        table {
          border: var(--unit-sm) solid var(--background-primary);
          background: var(--background-primary);
          border-radius: var(--radius-lg);
          border-spacing: 0px;
          margin: 0px;
          margin-right: auto;
          tr {
            td, th {
              padding: var(--unit-sm) var(--unit-lg);
              border: 1px solid var(--background-tertiary);
              &:not(:last-child) {
                border-right: none;
              }
            }
            &:not(:last-child) {
              td, th {
                border-bottom: none;
              }
            }
          }
          thead {
            tr {
              background: var(--background-tertiary);
              &:first-child {
                td, th {
                  text-align: left;
                  &:first-child {
                    border-top-left-radius: var(--radius);
                  }
                  &:last-child {
                    border-top-right-radius: var(--radius);
                  }
                }
              }
            }
          }
          tbody {
            tr {
              &:last-child {
                td, th {
                  &:first-child {
                    border-bottom-left-radius: var(--radius);
                  }
                  &:last-child {
                    border-bottom-right-radius: var(--radius);
                  }
                }
              }
            }
          }
        }
      }

      .chat-typing {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--unit-sm);
        width: calc(24px * 1.4);
        height: 24px;
        background: var(--background-tertiary);
        border-radius: 1000px;
        padding: 0px var(--unit-md);        
        .dot {
          width: var(--unit-sm);
          height: var(--unit-sm);
          border-radius: 1000px;
          background: var(--color-secondary);
          &:nth-child(1) {
            animation: typing-dot 1.5s linear infinite 0s;
          }
          &:nth-child(2) {
            animation: typing-dot 1.5s linear infinite 0.25s;
          }
          &:nth-child(3) {
            animation: typing-dot 1.5s linear infinite 0.5s;
          }
        }
      }
    }
  }
}

@keyframes typing-dot {
  0%, 60%, 100% {
    transform: translateY(0);
    opacity: 0.6;
  }
  30% {
    transform: translateY(-4px);
    opacity: 1;
  }
}
