[data-component="PackageCard"] {

  display: flex;
  background: var(--background-primary);
  border-radius: var(--radius-lg);
  padding: var(--unit-md);
  margin-bottom: var(--unit-md);
  border: 1px solid var(--outline-default);
  gap: var(--unit-md);

  &:last-child {
    margin-bottom: 0px;
  }

  .package-card-icon {
    display: flex;
    color: var(--color-disabled);
    margin-top: var(--unit-sm);
    gap: var(--unit-sm);
    svg {
      height: var(--unit-lg);
      width: var(--unit-lg);
      &.private {
        opacity: 0.5;
      }
    }
  }

  .package-card-details {
    display: flex;
    flex-direction: column;
    line-height: 1em;
    gap: var(--unit-sm);
    .package-card-name {
      display: flex;
      align-items: center;
      gap: var(--unit-sm);
    }
    .package-card-description {
      color: var(--color-secondary);
    }
  }

  .package-card-time {
    color: var(--color-disabled);
    font-size: var(--font-size-sm);
  }
}