[data-component="DashboardLinksPage"] {

  position: relative;
  max-width: var(--screen-md);
  width: 100%;
  padding: var(--unit-xl) calc(var(--unit-xl) * 2);

  @media screen and (max-width:567px) {
    padding: var(--unit-xl) var(--unit-lg);  
  }

  .content-container {
    margin: 0px auto;
    width: 100%;

    .content-description {
      margin: var(--unit-lg) 0px;
      font-size: var(--font-size-md);
      color: var(--color-primary);
    }

    .empty-state {
      background: var(--background-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--unit-lg);
      border-radius: var(--radius-lg);
      margin: var(--unit-lg) 0px;
      color: var(--color-disabled);
    }

    .item-group {
      display: flex;
      flex-wrap: wrap;
      gap: var(--unit-lg);

      .item-entry {
        position: relative;
        width: 360px;

        @media screen and (max-width:567px) {
          width: 100%;
        }
      }
      &:after {
        content: '';
        clear: both;
        display: block;
      }
    }

  }

  .no-connections {
    background: var(--background-primary);
    padding: var(--unit-lg);
    border-radius: var(--radius-lg);
    margin: var(--unit-lg) 0px;
    color: var(--color-disabled);
  }

}
