[data-component="AgentTradingCard"] {
  width: 300px;
  max-width: calc(100vw - var(--unit-xl));
  display: flex;
  flex-direction: column;
  position: relative;
  transition: transform 0.1s ease;
  perspective: 1000px;
  perspective-origin: center;
  transform-origin: center;
  transform-style: preserve-3d;
  overflow: visible;

  .canvas-background {
    position: absolute;
    z-index: 1;
    width: 500px;
    height: 500px;
    top: calc(50% - 250px);
    left: calc(50% - 250px);
    transform: translateZ(-50px);
    filter: blur(16px);
    -webkit-filter: blur(16px);
    pointer-events: none;
  }

  .agent-card-container {
    position: relative;
    transform: translateZ(0);
    transform: rotateX(var(--rotateX, 0deg)) rotateY(var(--rotateY, 0deg));
    box-shadow: var(--shadow-dark-sm);
    border-radius: var(--radius-xl);
    display: flex;
    flex-direction: column;
    z-index: 3;
  }

  .agent-card-background {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: var(--radius-xl);
    background: linear-gradient(
      to bottom,
      var(--background-primary) 0%,
      var(--background-primary) calc(var(--gradient-pos, 0%) - 50%),
      var(--background-tertiary) var(--gradient-pos, 0%),
      var(--background-primary) calc(var(--gradient-pos, 00%) + 50%),
      var(--background-primary) 100%
    );
    transition: background-position 0.1s ease;
    z-index: 4;
  }

  .agent-card-foreground {
    z-index: 5;
    background: var(--background-primary);
    padding: var(--unit-lg);
    position: relative;
    margin: 1px;
    border-radius: var(--radius-xl);
  }
  
  .agent-card-type {
    position: absolute;
    top: var(--unit-md);
    right: var(--unit-md);
    font-size: var(--font-size-xs);
    padding: var(--unit) var(--unit-sm);
    font-weight: var(--font-weight-medium);
    letter-spacing: 0.5px;
    text-transform: uppercase;
    border-radius: var(--radius);
    color: var(--color-secondary);
  }
  
  .agent-card-image {
    flex-shrink: 0;
    position: relative;
    width: 128px;
    height: 128px;
    border-radius: var(--radius);
    margin: var(--unit-xl) auto;
    margin-bottom: var(--unit-lg);
    img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1000px;
    }
  }

  .agent-card-name {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--unit-sm);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .agent-card-description {
    font-size: var(--font-size-sm);
    color: var(--color-secondary);
    margin-bottom: var(--unit-lg);
    white-space: pre-wrap;
    text-align: center;
  }
  
  .agent-card-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--unit-lg);
    a {
      display: inline-flex;
      align-items: center;
      color: var(--link-color);
      font-size: var(--font-size-sm);
      
      .icon {
        width: 12px;
        height: 12px;
        margin-left: var(--unit-sm);
      }
      
      &:hover {
        text-decoration: none;
        opacity: 0.8;
      }
    }
  }

  .agent-card-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: var(--unit-md);
  }

}