[data-component="InfoBox"] {

  display: flex;
  align-items: flex-start;
  border-radius: var(--radius-lg);
  padding: var(--unit-lg);
  margin-bottom: var(--unit-lg);
  background: var(--background-primary);
  color: var(--color-secondary);
  gap: var(--unit-lg);

  & > svg {
    flex-shrink: 0;
    width: var(--unit-lg);
    height: var(--unit-lg);
    color: var(--color-disabled);
    margin-top: calc(var(--unit-sm) + 1px);
    color: var(--blue-main);
  }

  & > span {
    h1 {
      display: inline-flex;
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-medium);
      margin: 0px var(--unit-lg);
      padding: var(--unit-lg) 0px;
      color: var(--color-primary);
      align-items: center;
      gap: var(--unit-md);
      border-bottom: 1px solid var(--outline-minimal);
      svg {
        width: var(--unit-xl);
        height: var(--unit-xl);
        color: var(--color-tertiary);
      }
    }
    strong {
      font-weight: var(--font-weight-medium);
      color: var(--color-primary);
    }
    span {
      line-height: var(--line-height-lg);
    }
    code {
      font-size: var(--font-size-sm);
      font-family: var(--font-family-monospace);
      color: var(--color-primary);
      border: 1px solid var(--outline-default);
      padding: var(--unit) var(--unit-sm);
      border-radius: var(--radius);
    }
  }

}